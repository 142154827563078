import { useState, useEffect } from "react";
import Competition from "../../models/Competition";
import DataService from "../../services/DataService";
import CompetitionList from "./CompetitionList";

class Props {
  dataService!: DataService;
}

const ListCompetitions = ({ dataService }: Props) => {
  const [competitions, setCompetitions] = useState<Competition[]>([]);

  useEffect(() => {
    const result = dataService.competitions.getAll();
    setCompetitions(result);
  }, [dataService.competitions]);

  const deleteCompetition = (id: number) => {
    dataService.competitions.delete(id);
    const result = [...competitions.filter((x) => x.id !== id)];
    setCompetitions(result);
  };

  return (
    <>
      <h3>Competition List</h3>
      <CompetitionList competitions={competitions} deleteCompetition={deleteCompetition} isReadOnly={false} />
    </>
  );
};

export default ListCompetitions;
