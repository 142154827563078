export function formatDate(dateStr: string): string {
  const parsedDate = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = { weekday:"long", day: "numeric", month: "long", year: "numeric" };
  const day = parsedDate.getDate();
  const suffix = getDaySuffix(day);
  const formattedDate = parsedDate.toLocaleDateString("en-GB", options).replace(/\b\d{1,2}\b/, `${day}${suffix}`); // format the date
  return formattedDate;
}

function getDaySuffix(day: number): string {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
