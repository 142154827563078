import TableRow from "../components/Leagues/TableRow";
import Competition from "./Competition";
import Competitor from "./Competitor";
import Id from "./Id";

export default class CompetitionHelper {
  static isActive = (competition: Competition) => {
    return competition?.startDate !== undefined && competition?.stopDate === undefined && competition?.completeDate === undefined;
  };

  static isComplete = (competition: Competition) => {
    return competition?.completeDate !== undefined;
  };

  static isAdmin = (competition: Competition, userId: Id | undefined): boolean => {
    const result = Boolean(userId) && competition?.admins.some((adminId) => adminId === userId);
    return result;
  };

  static getTable = (competition: Competition, competitors: Competitor[]): TableRow[] => {
    const table: TableRow[] = [];
    for (const competitorId of competition.competitorIds) {
      var completedFixturesInvolvingCompetitor = competition.fixtures.filter(
        (fixure) => fixure.resultDate && (fixure.competitor1Id === competitorId || fixure.competitor2Id === competitorId)
      );
      const row = new TableRow();
      const competitor = competitors.find((c) => c.id === competitorId);
      row.name = competitor!.name;
      row.played = completedFixturesInvolvingCompetitor.length;
      row.won = completedFixturesInvolvingCompetitor.filter(
        (fixture) =>
          (fixture.competitor1Id === competitorId && fixture.competitor1Score! > fixture.competitor2Score!) ||
          (fixture.competitor2Id === competitorId && fixture.competitor1Score! < fixture.competitor2Score!)
      ).length;
      row.drawn = completedFixturesInvolvingCompetitor.filter((fixture) => fixture.competitor1Score! === fixture.competitor2Score!).length;
      row.lost = row.played - (row.won + row.drawn);
      row.for = completedFixturesInvolvingCompetitor.reduce((acc, fixture) => {
        const total: number = fixture.competitor1Id === competitorId ? fixture.competitor1Score! : fixture.competitor2Score!;
        return acc + total;
      }, 0);
      row.against = completedFixturesInvolvingCompetitor.reduce((acc, fixture) => {
        const total = fixture.competitor2Id === competitorId ? fixture.competitor1Score! : fixture.competitor2Score!;
        return acc + total;
      }, 0);
      row.points = row.for;
      table.push(row);
    }
    const sortedTable = table.sort(
      (rowA, rowB) => rowB.points - rowA.points || rowB.for - rowA.for || rowA.against - rowB.against || rowB.won - rowA.won || rowB.drawn - rowA.drawn
    );
    return sortedTable;
  };
}
