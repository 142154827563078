import { Accordion } from "react-bootstrap";
import Competition from "../../models/Competition";
import Competitor from "../../models/Competitor";
import Fixture from "../../models/Fixture";
import Result from "../../models/Result";
import FixtureList from "../Fixtures/FixtureList";
import LeagueTable from "../Leagues/LeagueTable";
import Id from "../../models/Id";

class Props {
  userId: Id | undefined;
  competition: Competition = new Competition();
  competitors: Competitor[] = [];
  saveFixture?: (fixture: Fixture) => Result;
}

const CompetitionView = ({ userId, competition, competitors, saveFixture }: Props) => {
  return (
    <>
      <h2>{competition?.name}</h2>
      <Accordion alwaysOpen defaultActiveKey={["0", "1"]} className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Table</Accordion.Header>
          <Accordion.Body className="overflow-scroll">
            <LeagueTable competition={competition} competitors={competitors} />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Fixtures</Accordion.Header>
          <Accordion.Body>
            <FixtureList userId={userId} fixtures={competition?.fixtures} competitors={competitors} saveFixture={saveFixture} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default CompetitionView;
