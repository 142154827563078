import Competition from "../models/Competition";
import Fixture from "../models/Fixture";

export default class FixtureService {
  generateFixtures = (competition: Competition, timesToPlayEachOther: number): Fixture[] => {
    var competitors: (number | undefined)[]
     = [...competition!.competitorIds!.filter(id => typeof id !== 'string')] as number[];
    var count = competitors.length;
    if (count % 2 === 1) {
      competitors.push(undefined);
      count++;
    }
    var fixtureCount = count / 2;
    var home = competitors.splice(0, fixtureCount);
    var away = competitors.splice(0, fixtureCount);
    var fixtures: Fixture[] = [];
    var fixtureSetCount = count - 1;
    var flip = true;
    var id = 1;
    for (var fixtureSet = 0; fixtureSet < fixtureSetCount * timesToPlayEachOther; fixtureSet++) {
      for (var i = 0; i < fixtureCount; i++) {
        if (home[i] === undefined || away[i] === undefined) continue;
        const fixture = new Fixture();
        fixture.group = fixtureSet;
        fixture.id = id++;
        if (flip) {
          fixture.competitor1Id = home[i]!;
          fixture.competitor2Id = away[i]!;
        } else {
          fixture.competitor1Id = away[i]!;
          fixture.competitor2Id = home[i]!;
        }
        fixtures.push(fixture);
      }
      var tempAway = away[0];
      var tempHome = home[fixtureCount - 1];
      for (i = 0; i < fixtureCount - 1; i++) {
        away[i] = away[i + 1];
        if (i < fixtureCount) 
          home[fixtureCount - (i + 1)] = home[fixtureCount - (i + 2)];
      }
      home[1] = tempAway;
      away[fixtureCount - 1] = tempHome;
      flip = !flip;
    }
    return fixtures;
  };
}
