import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Accordion, Alert, Button, Col, Form, Row } from "react-bootstrap";
import User from "../../models/User";
import { TextField } from "../Fields/TextField";
import { AlertState, AlertStateType } from "../../models/AlertState";
import Result from "../../models/Result";
import { useNavigate } from "react-router-dom";

class Props {
  login!: (username: string, password: string) => Result;
}

const LoginForm = ({ login }: Props) => {
  const [alert, setAlert] = useState<AlertState>();

  const navigate = useNavigate();

  const onSubmit = async ({ username, password }: User) => {
    try {
      var result = login(username, password);
      if (!result?.success) throw result?.data;
      console.log("Logged in");
      navigate("/");
    } catch (error) {
      console.error(error);
      setAlert({
        type: AlertStateType.error,
        message: (error as string) ?? "Unknown Error",
      });
    }
  };

  const schema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  return (
    <Accordion alwaysOpen defaultActiveKey={["0"]} className="mb-4">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Login</Accordion.Header>
        <Accordion.Body>
          <Formik
            validationSchema={schema}
            validateOnMount={true}
            onSubmit={onSubmit}
            initialValues={{ username: "james", password: "" } as User}
            enableReinitialize
          >
            {({ handleSubmit, isValid, dirty, isSubmitting }) => (
              <Form noValidate onSubmit={handleSubmit} className="mb-4">
                <Row className="mb-4">
                  <Col md={6}>
                    <TextField name="username" label="Username" placeholder="Username" />
                  </Col>
                  <Col md={6}>
                    <TextField name="password" label="Password" type="password" placeholder="Password" />
                  </Col>
                </Row>
                {alert !== undefined && <Alert variant={alert.type === AlertStateType.success ? "success" : "danger"}>{alert.message}</Alert>}
                <Button disabled={!dirty || !isValid || isSubmitting} variant="primary" type="submit">
                  Login
                </Button>
              </Form>
            )}
          </Formik>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default LoginForm;
