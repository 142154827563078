import { Field, FieldProps } from "formik";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

class Props {
  name: string = "";
  label: string = "";
  defaultValue?: string = "";
  as?: React.ElementType<any>;
  children: React.ReactNode;
}

export const SelectField = (props: Props) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => {
        const isValid = !form.errors[field.name];
        return (
          <Form.Group as={props.as} controlId={props.name + "Field"}>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <OverlayTrigger placement="top" overlay={form.errors[field.name] ? <Tooltip>{form.errors[field.name] as string}</Tooltip> : <></>}>
              <Form.Select {...field} isValid={isValid} isInvalid={!isValid}>
                {props.children}
              </Form.Select>
            </OverlayTrigger>
          </Form.Group>
        );
      }}
    </Field>
  );
};
