import { Formik } from "formik";
import { Row, Col, Form, Button, InputGroup, Alert } from "react-bootstrap";
import DataService from "../../services/DataService";
import { useState } from "react";
import Competition from "../../models/Competition";
import { TextField } from "../Fields/TextField";
import CompetitionList from "../Competitions/CompetitionList";
import { isNotNullOrWhitespace } from "../../models/StringHelper";

class Props {
  dataService!: DataService;
}

const Home = ({ dataService }: Props) => {
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [message, setMessage] = useState("");

  const onSubmit = ({ value }: { value: string }) => {
    setMessage("");
    const competitions = dataService.competitions.search(value);
    setCompetitions(competitions);
    if (competitions.length === 0) setMessage("No matching competitions!");
  };

  return (
    <>
      <h3>Competition Manager</h3>
      <h5>Streamline Your Competition Management</h5>
      <Row>
        <Col>
          Competition Manager is an innovative platform designed for anyone who wants to create and manage their own
          competitions. Whether you're a professional event organizer or a casual hobbyist, our website offers
          everything you need to host thrilling and successful contests. With easy-to-use tools and a user-friendly
          interface, you can set up and run your competition with ease. Plus, our platform offers a range of features,
          including real-time scoring, automated registration, and much more. Join Competition Manager today and take
          your competitions to the next level!
        </Col>
      </Row>
      <Row>
        <Col>
          <Formik onSubmit={onSubmit} initialValues={{ value: "" }} enableReinitialize>
            {({ handleSubmit, values, errors, isValid, dirty, isSubmitting, resetForm }) => (
              <Form noValidate onSubmit={handleSubmit} className="my-4">
                <InputGroup>
                  <TextField name="value" />
                  <Button variant="primary" type="submit">
                    Search
                  </Button>
                </InputGroup>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
      {isNotNullOrWhitespace(message) && (
        <Row>
          <Col>
            <Alert variant="warning">{message}</Alert>
          </Col>
        </Row>
      )}
      {competitions && competitions.length > 0 && (
        <Row>
          <Col>
            <CompetitionList competitions={competitions} isReadOnly={true} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Home;
