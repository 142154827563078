import { useState, useEffect } from 'react';
import { ThemeContext, Themes } from './ThemeContext';

class Props {
  children: any;
}

export default function ThemeContextWrapper(props: Props) {
  const [theme, setTheme] = useState(Themes.light);

  function changeTheme() {
    var switchedTheme = theme === Themes.dark ? Themes.light : Themes.dark
    setTheme(switchedTheme);
  }

  useEffect(() => {
    document.body.dataset.bsTheme = theme;
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}