import Competition from "../../models/Competition";
import Result from "../../models/Result";
import { isNullOrWhitespace } from "../../models/StringHelper";
import AuthenticationService from "../AuthenticationService";
import DataRepository from "../DataRepository";

class InMemoryCompetitionRepository implements DataRepository<Competition, number> {
  private competitions: Competition[];

  constructor(private authenticationService: AuthenticationService) {
    this.competitions = [
      {
        id: 1,
        name: "League 1",
        type: "League",
        startDate: undefined,
        completeDate: undefined,
        competitorIds: [1, 2, 3, 4, 5],
        fixtures: [],
        admins: [1],
        private: false,
      },
      {
        id: 2,
        name: "League 2",
        type: "Knockout",
        startDate: undefined,
        completeDate: undefined,
        competitorIds: [1, 2, 3, 4, 5],
        fixtures: [],
        admins: [1],
        private: false,
      },
    ];
  }

  isAuthorised(competition: Competition): boolean {
    const userId = this.authenticationService.getLoggedInUserId();
    if (!userId) return false;
    var result = competition.admins.some((adminId) => adminId === userId);
    return result;
  }

  search(value: string, limit = 10): Competition[] {
    if (isNullOrWhitespace(value)) return [];
    const filteredCompetitions = this.competitions.filter(
      (competition) => !competition.private && competition.name.toLowerCase().includes(value.toLocaleLowerCase())
    );
    const limitedCompetitions = filteredCompetitions.slice(0, limit);
    return limitedCompetitions;
  }

  get(id: number): Competition | undefined {
    const competition = this.competitions.find((x) => x.id === id);
    return competition;
  }

  getAll(): Competition[] {
    return this.competitions;
  }

  save(model: Competition): Result {
    if (!model.name) {
      return { success: false, data: "Competition name required!!!" };
    }
    if (!model.type) {
      return { success: false, data: "Competition type required!!!" };
    }
    if (!model.id) {
      model.id = this.competitions.length + 1;
      this.competitions.push(model);
    } else {
      const index = this.competitions.findIndex((competition) => competition.id === model.id);
      this.competitions[index] = model;
    }
    return { success: true };
  }

  delete(id: number): Result {
    const index = this.competitions.findIndex((x) => x.id === id);
    if (index < 0) {
      return { success: false, data: "Competition not found to delete !!!" };
    }
    this.competitions.splice(index, 1);
    return { success: true };
  }
}

export default InMemoryCompetitionRepository;
