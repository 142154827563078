import { useState, useEffect, SyntheticEvent } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Competition from "../../models/Competition";
import CompetitionForm from "./CompetitionForm";
import FixtureService from "../../services/FixtureService";
import CompetitionHelper from "../../models/CompetitionHelper";
import CompetitionView from "./CompetitionView";
import Fixture from "../../models/Fixture";
import Result from "../../models/Result";
import Competitor from "../../models/Competitor";
import DataService from "../../services/DataService";
import { AlertState, AlertStateType } from "../../models/AlertState";

class Props {
  dataService!: DataService;
}

const EditCompetition = ({ dataService }: Props) => {
  const [competition, setCompetition] = useState<Competition>();
  const [competitors, setCompetitors] = useState<Competitor[]>();

  const [alert, setAlert] = useState<AlertState>();

  const params = useParams<{ id: string }>();
  const id = parseInt(params.id!);

  const userId = dataService.authentication.getLoggedInUserId();

  const onSubmit = (competition: Competition) => {
    setAlert(undefined);
    for (let i = 0; i < competition.competitorIds.length; i++) {
      if (typeof competition.competitorIds[i] === "string") {
        const competitor = new Competitor();
        competitor.name = competition.competitorIds[i] as string;
        dataService.competitiors.save(competitor);
        competition.competitorIds[i] = competitor.id as number;
      }
    }
    const result = dataService.competitions.save(competition);
    if (result.success) {
      setAlert({
        type: AlertStateType.success,
        message: "Saved successfully",
      });
    } else {
      setAlert({
        type: AlertStateType.error,
        message: result.data ?? "Unknown Error",
      });
    }
    setCompetition(competition);
    return true;
  };

  const saveFixture = (fixture: Fixture): Result => {
    var newCompetition = { ...competition! };
    var fixtures: Fixture[] = [...newCompetition.fixtures];
    var index = fixtures.findIndex((existingFixture) => fixture.id === existingFixture.id);
    if (index < 0) {
      return {
        success: false,
        data: "Fixture not found",
      };
    }
    fixtures[index] = fixture;
    newCompetition.fixtures = fixtures;
    let result: Result;
    result = dataService.competitions.save(newCompetition);
    result.data = result.success ? "Saved successfully" : result.data ?? "Unknown Error";
    setCompetition(newCompetition);
    return result;
  };

  const navigate = useNavigate();

  const onBack = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate("/list-competitions");
  };

  useEffect(() => {
    const competitionsResult = dataService.competitions.get(id);
    if (!CompetitionHelper.isAdmin(competitionsResult!, dataService.authentication.getLoggedInUserId())) {
      navigate("/error/?message=Access Denied!");
    }
    setCompetition(competitionsResult);
    const competitorsResult = dataService.competitiors.getAll();
    setCompetitors(competitorsResult);
  }, [dataService, id, navigate]);

  const onStartCompetition = (e: SyntheticEvent) => {
    e.preventDefault();
    const fixtureService = new FixtureService();
    const fixtures = fixtureService.generateFixtures(competition!, 1);
    var newCompetition = { ...competition! };
    newCompetition.startDate = new Date(Date.now());
    newCompetition.stopDate = undefined;
    newCompetition.completeDate = undefined;
    newCompetition.fixtures = fixtures;
    const result = dataService.competitions.save(newCompetition);
    if (result.success) {
      setAlert({
        type: AlertStateType.success,
        message: "Competition Started",
      });
    } else {
      setAlert({
        type: AlertStateType.error,
        message: result.data ?? "Unknown Error",
      });
    }
    setCompetition(newCompetition);
  };

  const onStopCompetition = (e: SyntheticEvent) => {
    e.preventDefault();
    var newCompetition = { ...competition! };
    newCompetition.startDate = undefined;
    newCompetition.stopDate = new Date(Date.now());
    newCompetition.completeDate = undefined;
    newCompetition.fixtures = [];
    const result = dataService.competitions.save(newCompetition);
    if (result.success) {
      setAlert({
        type: AlertStateType.success,
        message: "Competition Stopped",
      });
    } else {
      setAlert({
        type: AlertStateType.error,
        message: result.data ?? "Unknown Error",
      });
    }
    setCompetition(newCompetition);
  };

  return (
    <>
      <h3>Edit Competition</h3>
      <Row>
        <Col sm>
          {!CompetitionHelper.isActive(competition!) ? (
            <CompetitionForm competition={competition!} competitors={competitors!} onSubmit={onSubmit} />
          ) : (
            <CompetitionView userId={userId} competition={competition!} competitors={competitors!} saveFixture={saveFixture} />
          )}
          {alert !== undefined && <Alert variant={alert.type === AlertStateType.success ? "success" : "danger"}>{alert.message}</Alert>}
          <hr />
          {CompetitionHelper.isActive(competition!) ? (
            <Button variant="danger" onClick={onStopCompetition}>
              Stop Competition
            </Button>
          ) : CompetitionHelper.isComplete(competition!) ? (
            <Alert variant="success">Competition Complete</Alert>
          ) : (
            <Button variant="success" onClick={onStartCompetition}>
              Start Competition
            </Button>
          )}{" "}
          <Button variant="secondary" onClick={(e) => onBack(e)}>
            Back
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default EditCompetition;
