import { Container } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
//import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import Header from './components/Page/Header';
import Footer from "./components/Page/Footer";
import InMemoryDataService from "./services/InMemoryServices/InMemoryDataService";
import Routing from "./components/Routes/Routing";
import { useState } from "react";
import Result from "./models/Result";

const App = () => {
  const [dataService] = useState(new InMemoryDataService());
  const [loggedIn, setLoggedIn] = useState(dataService.authentication.isLoggedIn());

  const login = (username: string, password: string) : Result => {
    logout();
    const result = dataService.authentication.login(username, password);
    setLoggedIn(result!.success);
    return result!;
  };

  const logout = () => {
    dataService.authentication.logout();
    setLoggedIn(false);
  }

  return (
    <Router>
      <div className="App">
        <Header loggedIn={loggedIn}/>
        <main style={{paddingTop: '1.5rem', paddingBottom: '6rem'}}>
          <Container>
            <Routing dataService={dataService} login={login} logout={logout} />
          </Container>
        </main>
        <Footer />
      </div>
    </Router>
  )
}

export default App;
