import { useState, useEffect, SyntheticEvent } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Competition from "../../models/Competition";
import CompetitionHelper from "../../models/CompetitionHelper";
import CompetitionView from "./CompetitionView";
import Fixture from "../../models/Fixture";
import Result from "../../models/Result";
import Competitor from "../../models/Competitor";
import DataService from "../../services/DataService";
import { AlertState, AlertStateType } from "../../models/AlertState";

class Props {
  dataService!: DataService;
}

const ViewCompetition = ({ dataService }: Props) => {
  const [competition, setCompetition] = useState<Competition>(new Competition());
  const [competitors, setCompetitors] = useState<Competitor[]>([]);

  const [alert, setAlert] = useState<AlertState>();

  const params = useParams<{ id: string }>();
  const id = parseInt(params.id!);

  const userId = dataService.authentication.getLoggedInUserId();

  const saveFixture = (fixture: Fixture): Result => {
    var newCompetition = { ...competition! };
    var fixtures: Fixture[] = [...newCompetition.fixtures];
    var index = fixtures.findIndex((existingFixture) => fixture.id === existingFixture.id);
    if (index < 0) {
      return {
        success: false,
        data: "Fixture not found",
      };
    }
    fixtures[index] = fixture;
    newCompetition.fixtures = fixtures;
    let result: Result;
    result = dataService.competitions.save(newCompetition);
    result.data = result.success ? "Saved successfully" : result.data ?? "Unknown Error";
    setCompetition(newCompetition);
    return result;
  };

  const navigate = useNavigate();

  const onBack = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate("/list-competitions");
  };

  useEffect(() => {
    const competitionsResult = dataService.competitions.get(id);
    setCompetition(competitionsResult!);
    const competitorsResult = dataService.competitiors.getAll();
    setCompetitors(competitorsResult);
  }, [dataService, id]);

  const onStopCompetition = (e: SyntheticEvent) => {
    e.preventDefault();
    var newCompetition = { ...competition! };
    newCompetition.startDate = undefined;
    newCompetition.stopDate = new Date(Date.now());
    newCompetition.completeDate = undefined;
    newCompetition.fixtures = [];
    const result = dataService.competitions.save(newCompetition);
    if (result.success) {
      navigate(`/edit-competition/${newCompetition.id}`);
    }
    setAlert({
      type: AlertStateType.error,
      message: result.data ?? "Unknown Error",
    });
  };

  return (
    <>
      <h3>View Competition</h3>
      <Row>
        <Col sm>
          <CompetitionView userId={userId} competition={competition!} competitors={competitors!} saveFixture={saveFixture} />
          {alert !== undefined && <Alert variant={alert.type === AlertStateType.success ? "success" : "danger"}>{alert.message}</Alert>}
          <hr />
          {CompetitionHelper.isActive(competition!) &&
            ((CompetitionHelper.isComplete(competition!) ? (
              <Button variant="danger" onClick={onStopCompetition}>
                Stop Competition
              </Button>
            ) : (
              <Alert variant="success">Competition Complete</Alert>
            )))}{" "}
          <Button variant="secondary" onClick={(e) => onBack(e)}>
            Back
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ViewCompetition;
