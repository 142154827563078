import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Button, Form } from "react-bootstrap";
import Fixture from "../../models/Fixture";
import { useState } from "react";
import Result from "../../models/Result";
import Competitor from "../../models/Competitor";
import CompetitorHelper from "../../models/CompetitorHelper";
import { TextField } from "../Fields/TextField";

class Props {
  competitors!: Competitor[];
  fixture!: Fixture;
  saveFixture!: (fixture: Fixture) => Result;
  saved!: () => void;
  cancel!: () => void;
}

const FixtureRow = ({ competitors, fixture, saveFixture, saved, cancel }: Props) => {
  const schema = Yup.object().shape({
    competitor1Score: Yup.number().required("Score is required"),
    competitor2Score: Yup.number().required("Score is required"),
    resultDate: Yup.string().required("Date is required"),
  });

  const [error, setError] = useState<string>();

  const onSubmit = (fixture: Fixture) => {
    var result = saveFixture(fixture);
    if (!result.success) setError(result.data);
    else saved();
  };

  return (
    <Formik
      validationSchema={schema}
      validateOnMount={true}
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        id: fixture.id || 0,
        group: fixture.group || 0,
        competitor1Id: fixture.competitor1Id || 0,
        competitor2Id: fixture.competitor2Id || 0,
        resultDate: fixture.resultDate || "",
        competitor1Score: fixture.competitor1Score,
        competitor2Score: fixture.competitor2Score,
      }}
    >
      {({ handleSubmit, isValid, dirty, isSubmitting }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="align-items-center">
            <Col xs={12} md={7}>
              <Row className="align-items-center justify-content-between">
                <Col xs={{ span: 7, order: 1 }} sm className="text-sm-end fw-bold">
                  {CompetitorHelper.competitorName(competitors, fixture.competitor1Id)}
                </Col>
                <Col xs={{ span: "auto", order: 2 }} sm={{ span: "auto", order: 2 }} className="text-end align-middle p-sm-0 me-sm-1">
                  <TextField name="competitor1Score" type="number" width="4rem" />
                </Col>
                <Col xs={{ span: "auto", order: 4 }} sm={{ span: "auto", order: 3 }} className="text-end align-middle pt-2 p-sm-0">
                  <TextField name="competitor2Score" type="number" width="4rem" />
                </Col>
                <Col xs={{ span: 7, order: 3 }} sm={{ span:true, order: 4 }} className="text-start fw-bold pt-2 pt-sm-0">
                  {CompetitorHelper.competitorName(competitors, fixture.competitor2Id)}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md>
              <Row className="align-items-center justify-content-between justify-content-sm-center justify-content-md-end pt-2 pt-md-0">
                <Col xs="auto" className="fw-bold">
                  Date
                </Col>
                <Col xs="auto" className="ps-sm-0 text-end text-sm-start">
                  <TextField type="date" name="resultDate" width="10rem" />
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg="auto" className="pt-2 pt-lg-0 text-center text-md-end">
              <Button className="me-2" disabled={!dirty || !isValid || isSubmitting} variant="success" as="input" size="sm" type="submit" value="Save" />
              <Button
                type="submit"
                size="sm"
                variant="danger"
                onClick={(e) => {
                  e.preventDefault();
                  cancel();
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default FixtureRow;
