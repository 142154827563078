import Id from "../../models/Id";
import Result from "../../models/Result";
import User from "../../models/User";
import AuthenticationService from "../AuthenticationService";

class InMemoryAuthenticationService implements AuthenticationService {
  private users: User[];

  constructor() {
    this.users = [
      {
        id: 1,
        username: "james",
        password: "jjj",
      },
    ];
  }

  getLoggedInUserId(): Id | undefined {
    const token = localStorage.getItem("token")
    if (token == null)
      return undefined;
    const id = JSON.parse(token) as Id;
    return id;
  }

  createToken(id: Id): string {
    const token = JSON.stringify(id)
    return token;
  }

  logout(): void {
    localStorage.removeItem("token");
  }

  isLoggedIn(): boolean {
    return localStorage.getItem("token") != null;
  }

  login(username: string, password: string): Result | undefined {
    if (!username) return { success: false, data: "Username required!!!" };
    if (!password) return { success: false, data: "Password required!!!" };
    const user = this.users.find((user) => user.username === username);
    if (user?.password !== password) return { success: false, data: "Invalid Username/Password" };
    const token = this.createToken(user.id!);
    localStorage.setItem("token", token);
    return { success: true, data: token };
  }

  signup(username: string, password: string): Result | undefined {
    if (!username) return { success: false, data: "Username required!!!" };
    if (!password) return { success: false, data: "Password required!!!" };
    const exists = this.users.some((user) => user.username === username);
    if (exists) return { success: false, data: "Username already exists" };
    const user:User = {id: this.users.length+1, username, password}
    this.users.push(user);
    const token = this.createToken(user.id!);
    localStorage.setItem("token", token);
    return { success: true, data: token };
  }
}

export default InMemoryAuthenticationService;
