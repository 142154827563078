import { Alert, Button, Table } from "react-bootstrap";
import { Link45deg, Plus } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Competitor from "../../models/Competitor";
import CompetitorHelper from "../../models/CompetitorHelper";

class Props {
  competitors!: Competitor[];
  competitorIds: (number | string)[] = [];
  deleteCompetitor!: (competitorId: number | string) => void;
}

const CompetitorList = ({ competitors, competitorIds, deleteCompetitor }: Props) => {
  return (
    <div className="table-wrapper">
      {competitors === undefined || competitors.length === 0 ? (
        <Alert variant="warning">No competitors</Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th className="fit">Action</th>
            </tr>
          </thead>
          <tbody>
            {competitorIds.map((competitorId, i) => (
              <tr key={i}>
                <td>
                  {typeof competitorId === "string" ? (
                    <span>

                      <Plus size={24} className="me-2" />
                      {competitorId}
                    </span>
                  ) : (
                    <span>
                      <Link45deg size={24} className="me-2" />
                      {CompetitorHelper.competitorName(competitors, competitorId)}
                    </span>
                  )}
                </td>
                <td className="text-nowrap">
                  <Link className="edit-link" to={"/edit-competitor/" + competitorId}>
                    <Button size="sm" variant="primary">
                      Edit
                    </Button>
                  </Link>{" "}
                  <Button onClick={() => deleteCompetitor(competitorId!)} size="sm" variant="danger">
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default CompetitorList;
