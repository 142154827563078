import { Table } from "react-bootstrap";
import Competition from "../../models/Competition";
import Competitor from "../../models/Competitor";
import CompetitionHelper from "../../models/CompetitionHelper";

class Props {
  competition!: Competition;
  competitors!: Competitor[];
}

const LeagueTable = ({ competition, competitors }: Props) => {

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Name</th>
          <th>Played</th>
          <th>Won</th>
          <th>Drawn</th>
          <th>Lost</th>
          <th>For</th>
          <th>Against</th>
          <th>Pts</th>
        </tr>
      </thead>
      <tbody>
        {CompetitionHelper.getTable(competition, competitors).map((row, i) => (
          <tr key={i}>
            <td>{row.name}</td>
            <td>{row.played}</td>
            <td>{row.won}</td>
            <td>{row.drawn}</td>
            <td>{row.lost}</td>
            <td>{row.for}</td>
            <td>{row.against}</td>
            <td>{row.points}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default LeagueTable;
