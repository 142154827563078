import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { isNullOrWhitespace } from "../../models/StringHelper";

class Props {
  title?: string = "";
  message?: string = "";
}

export default function Error({ title, message }: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  if (isNullOrWhitespace(title)) {
    title = queryParams.get("title") ?? "Error!";
  }
   if (isNullOrWhitespace(message)) {
    message = queryParams.get("message") ?? "Unknown Error!";
  }

  useEffect(() => {
    setErrorTitle(title ?? "");
    setErrorMessage(message ?? "");
  }, [title, message]);

  return (
    <>
      <h3>{errorTitle}</h3>
      <Row>
        <Col>{errorMessage}</Col>
      </Row>
    </>
  );
}
