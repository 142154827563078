import { Navbar, Container, FormCheck, Row, Col } from "react-bootstrap";
import FrostwareLogo from "../Icons/FrostwareLogo";
import { ThemeContext } from "../Themes/ThemeContext";

export default function Footer() {
  return (
    <footer className="fixed-bottom">
      <Navbar bg="dark" variant="dark" expand="md">
        <Container className="d-flex justify-content-end">
          <Row>
            <Col>
              {" "}
              <ThemeContext.Consumer>
                {({ changeTheme }) => (
                  <div className="d-flex align-items-center">
                    <label className="me-1">Dark Mode</label>
                    <FormCheck
                      type="switch"
                      id="dark-mode"
                      onClick={() => {
                        changeTheme();
                      }}
                    />
                  </div>
                )}
              </ThemeContext.Consumer>
            </Col>
            <Col xs={"auto"}  className="d-flex align-items-center">
              <div>
                &copy; 2023{" "}
                <a href="http://frostware.co.uk">
                  <FrostwareLogo />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </footer>
  );
}
