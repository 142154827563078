import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Competition from "../../models/Competition";
import CompetitionName from "./CompetitionName";

class Props {
  competitions: Competition[] = [];
  isReadOnly = true;
  deleteCompetition?: (id: number) => void;
}

const CompetitionList = ({ competitions, isReadOnly, deleteCompetition }: Props) => {
  return (
    <div className="table-wrapper">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            {!isReadOnly && <th className="fit">Action</th>}
          </tr>
        </thead>
        <tbody>
          {competitions.map((competition, i) => (
            <tr key={i}>
              <td>
                <CompetitionName competition={competition} />
              </td>
              {!isReadOnly && (
                <td className="text-nowrap">
                  <Link className="edit-link" to={"/edit-Competition/" + competition.id}>
                    <Button size="sm" variant="primary">
                      Edit
                    </Button>
                  </Link>{" "}
                  {deleteCompetition && (
                    <Button onClick={() => deleteCompetition(competition.id!)} size="sm" variant="danger">
                      Delete
                    </Button>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CompetitionList;
