import Competitor from "../../models/Competitor";
import Result from "../../models/Result";
import { isNullOrWhitespace } from "../../models/StringHelper";
import DataRepository from "../DataRepository";

class InMemoryCompetitorRepository implements DataRepository<Competitor, number> {
  private competitors: Competitor[];

  constructor() {
    this.competitors = [
      { id: 1, name: "James Frost", private: true },
      { id: 2, name: "Dan Fewell", private: true },
      { id: 3, name: "Alan Arnold", private: true },
      { id: 4, name: "Steve McDonald", private: true },
      { id: 5, name: "Matt Parker", private: true },
      { id: 6, name: "Anthony Robinson", private: true },
    ];
  }

  search(value: string, limit = 10): Competitor[] {
    if (isNullOrWhitespace(value)) return [];
    const filteredCompetitions = this.competitors.filter(
      (competitor) => !competitor.private && competitor.name.includes(value)
    );
    const limitedCompetitions = filteredCompetitions.slice(0, limit);
    return limitedCompetitions;
  }

  get(id: number): Competitor | undefined {
    return this.competitors.find((x) => x.id === id);
  }

  getAll(): Competitor[] {
    return this.competitors;
  }

  save(model: Competitor): Result {
    if (!model.name) {
      return { success: false, data: "competitor name required!!!" };
    }
    if (!model.id) {
      model.id = this.competitors.length + 1;
      this.competitors.push(model);
    } else {
      const index = this.competitors.findIndex((competitor) => competitor.id === model.id);
      this.competitors[index] = model;
    }
    return { success: true };
  }

  delete(id: number): Result {
    const index = this.competitors.findIndex((x) => x.id === id);
    if (index < 0) {
      return { success: false, data: "competitor not found to delete !!!" };
    }
    this.competitors.splice(index, 1);
    return { success: true };
  }
}

export default InMemoryCompetitorRepository;
