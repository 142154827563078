import Competition from "../../models/Competition";
import KnockoutIcon from "../Icons/KnockoutIcon";
import LeagueIcon from "../Icons/LeagueIcon";

class Props {
  competition!: Competition;
}

const CompetitionName = ({ competition }: Props) => {
  return (
    <span className="d-flex align-items-center">
      {competition.type === "League" ? <LeagueIcon className="me-2" /> : <KnockoutIcon className="me-2" />}
      {competition.name}
    </span>
  );
};

export default CompetitionName;
