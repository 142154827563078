import { SyntheticEvent, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Competition from "../../models/Competition";
import Competitor from "../../models/Competitor";
import DataService from "../../services/DataService";
import CompetitionForm from "./CompetitionForm";

class Props {
  dataService!: DataService;
}

const CreateCompetition = ({ dataService }: Props) => {
  const [currentUser, setCurrentUser] = useState<number>();
  const [competition] = useState(new Competition());
  const [competitors, setCompetitors] = useState<Competitor[]>();

  const navigate = useNavigate();

  const onSubmit = (competition: Competition) => {
    if (competition.admins.indexOf(currentUser!) < 0) {
      competition.admins.push(currentUser!);
    }
    competition.admins.push(currentUser!);
    const result = dataService.competitions.save(competition);
    if (result.success) navigate("/list-competitions");
    return true;
  };

  const onBack = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate("/list-competitions");
  };

  useEffect(() => {
    const competitorsResult = dataService.competitiors.getAll();
    setCompetitors(competitorsResult);
    const loggedInUserResult = dataService.authentication.getLoggedInUserId();
    setCurrentUser(loggedInUserResult);
  }, [dataService]);

  return (
    <>
      <h3>Create Competition</h3>
      <CompetitionForm competition={competition} competitors={competitors!} onSubmit={onSubmit}/>
      <hr />
      <Button variant="secondary" onClick={(e) => onBack(e)}>
        Back
      </Button>
    </>
  );
};

export default CreateCompetition;
