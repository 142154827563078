import { Outlet, Navigate, Routes, Route } from "react-router-dom";
import LoginForm from "../Authentication/LoginForm";
import CreateCompetition from "../Competitions/CreateCompetition";
import EditCompetition from "../Competitions/EditCompetition";
import ListCompetitions from "../Competitions/ListCompetitions";
import DataService from "../../services/DataService";
import Result from "../../models/Result";
import Logout from "../Authentication/Logout";
import Home from "../Page/Home";
import ViewCompetition from "../Competitions/ViewCompetition";
import Error from "../Page/Error";

class DataServiceProps {
  dataService!: DataService;
}

class Props extends DataServiceProps {
  logout!: () => void;
  login!: (username: string, password: string) => Result;
}

const ProtectedWrapper = ({ dataService }: DataServiceProps) => {
  const isAuthenticated = dataService.authentication.isLoggedIn();
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

const Routing = ({ dataService, login, logout }: Props) => (
  <Routes>
    <Route path="/" element={<Home dataService={dataService} />} />
    <Route path="/login" element={<LoginForm login={login} />} />
    <Route path="/logout" element={<Logout logout={logout} />} />
    <Route element={<ProtectedWrapper dataService={dataService} />}>
      <Route path="/create-competition" element={<CreateCompetition dataService={dataService} />} />
    </Route>
    <Route element={<ProtectedWrapper dataService={dataService} />}>
      <Route path="/edit-competition/:id" element={<EditCompetition dataService={dataService} />} />
    </Route>
    <Route path="/view-competition/:id" element={<ViewCompetition dataService={dataService} />} />
    <Route element={<ProtectedWrapper dataService={dataService} />}>
      <Route path="/list-competitions" element={<ListCompetitions dataService={dataService} />} />
    </Route>
    <Route path="/error" element={<Error />} />
    <Route path="*" element={<Error title="Not Found!" message="Page not found!" />} />
  </Routes>
);

export default Routing;
