import Competition from "../../models/Competition";
import Competitor from "../../models/Competitor";
import AuthenticationService from "../AuthenticationService";
import DataRepository from "../DataRepository";
import DataService from "../DataService";
import InMemoryAuthenticationService from "./InMemoryAuthenticationSevice";
import InMemoryCompetitionRepository from "./InMemoryCompetitionRepository";
import InMemoryCompetitorRepository from "./InMemoryCompetitorRepository";

class InMemoryDataService implements DataService {
  authentication: AuthenticationService = new InMemoryAuthenticationService();
  competitions: DataRepository<Competition, number> = new InMemoryCompetitionRepository(this.authentication);
  competitiors: DataRepository<Competitor, number> = new InMemoryCompetitorRepository();
}

export default InMemoryDataService;
