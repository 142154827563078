import { useState } from "react";
import { Row, Col, Button, Collapse } from "react-bootstrap";
import Competitor from "../../models/Competitor";
import Fixture from "../../models/Fixture";
import Result from "../../models/Result";
import FixtureForm from "./FixtureForm";
import CompetitorHelper from "../../models/CompetitorHelper";
import { formatDate } from "../../models/DateHelper";
import Id from "../../models/Id";

class Props {
  userId : Id | undefined;
  competitors!: Competitor[];
  fixture!: Fixture;
  index!: number;
  saveFixture?: (fixture: Fixture) => Result;
}

const FixtureRow = ({ userId, competitors, fixture, index, saveFixture }: Props) => {
  const [editing, setEditing] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const canAddResult = () => Boolean(userId && saveFixture);

  const addResult = () => {
    if (canAddResult()) setEditing(true);
  };

  const cancel = () => {
    setEditing(false);
  };

  const saved = () => {
    setEditing(false);
  };

  return editing && canAddResult() ? (
    <FixtureForm competitors={competitors} fixture={fixture} saveFixture={saveFixture!} cancel={cancel} saved={saved} />
  ) : (
    <Row>
      <Col md={7}>
        <Row className="align-items-center">
          <Col className="text-end fw-bold">{CompetitorHelper.competitorName(competitors, fixture.competitor1Id)}</Col>
          {fixture.resultDate ? (
            <>
              <Col xs="auto" className="bg-warning text-dark me-1 fw-bold">
                {fixture.competitor1Score}
              </Col>
              <Col xs="auto" className="bg-warning text-dark fw-bold">
                {fixture.competitor2Score}
              </Col>
            </>
          ) : (
            <Col xs="auto">vs</Col>
          )}
          <Col className="fw-bold">{CompetitorHelper.competitorName(competitors, fixture.competitor2Id)}</Col>
        </Row>
        <Collapse in={showInfo}>
          <Row>
            <Col className="pt-2 text-center">{fixture.resultDate ? <span>{formatDate(fixture.resultDate)}</span> : <span>No info</span>}</Col>
          </Row>
        </Collapse>
      </Col>
      <Col md className="text-center text-md-end pt-2 pt-md-0">
        {!fixture.resultDate && canAddResult() && (
          <>
            <Button
              type="button"
              size="sm"
              variant="success"
              onClick={(e) => {
                e.preventDefault();
                addResult();
              }}
            >
              Add Result
            </Button>{" "}
          </>
        )}
        <Button size="sm" variant="info" onClick={() => setShowInfo(!showInfo)}>
          {showInfo ? "Hide" : "Show"} Info
        </Button>
      </Col>
    </Row>
  );
};

export default FixtureRow;
