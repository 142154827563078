import Fixture from "./Fixture";
import AdministerableDataModel from "./AdministerableDataModel";

class Competition extends AdministerableDataModel {
  name: string = "";
  type: string = "";
  startDate?: Date;
  stopDate?: Date;
  completeDate?: Date;
  competitorIds: (number | string)[] = [];
  fixtures: Fixture[] = [];
  private: boolean = true;
}

export default Competition;
