import React, { useState, useEffect } from "react";
import { Form, FormControl, ListGroup } from "react-bootstrap";

interface AutoCompleteProps {
  value: string | { id: number; name: string };
  options: { id: number; name: string }[];
  onSelect: (selected: string | { id: number; name: string }) => void;
  controlStyle?: React.CSSProperties;
}

interface FilteredOption {
  id: number;
  name: string;
  highlighted: boolean;
}

const AutoComplete = ({ value, options, onSelect, controlStyle }: AutoCompleteProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState<string | { id: number; name: string }>("");
  const [filteredOptions, setFilteredOptions] = useState<FilteredOption[]>([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  useEffect(() => {
    setInputValue((typeof value === "string" ? value : value?.name) ?? "");
    setSelectedValue(value);
    setFilteredOptions(
      options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase())).map((option) => ({ ...option, highlighted: false }))
    );
  }, [value, selectedValue, inputValue, options]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setSelectedValue(e.target.value);
    if (filteredOptions) setHighlightedIndex(filteredOptions.findIndex((option) => option.name === e.target.value));
    onSelect(e.target.value);
  };

  const handleSelect = (selectedOption: FilteredOption) => {
    setInputValue(selectedOption.name);
    setSelectedValue(selectedOption);
    onSelect(selectedOption);
    setFilteredOptions([]);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prev) => (prev + 1) % filteredOptions.length);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prev) => (prev - 1 + filteredOptions.length) % filteredOptions.length);
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (highlightedIndex > -1) {
        handleSelect(filteredOptions[highlightedIndex]);
      } else {
        onSelect(inputValue);
      }
    }
  };

  const handleFocus = () => {
    setShowOptions(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowOptions(false);
    }, 200);
  };

  const optionsStyle: React.CSSProperties = {
    position: "absolute",
    zIndex: 9999,
    top: "3px",
  };

  return (
    <Form.Group>
      <Form.Control
        type="text"
        value={inputValue}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="off"
        style={controlStyle}
      />
      {filteredOptions.length > 0 && showOptions && (
        <div style={{ position: "relative" }}>
          <ListGroup style={optionsStyle}>
            {filteredOptions.map((option, index) => (
              <ListGroup.Item
                key={option.id}
                active={index === highlightedIndex}
                onClick={() => handleSelect(option)}
                onMouseEnter={() => setHighlightedIndex(index)}
              >
                {option.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      )}
    </Form.Group>
  );
};

export default AutoComplete;
