import { useState } from "react";
import { Accordion, Alert, Col, Pagination, Row } from "react-bootstrap";
import { distinctBy } from "../../models/ArrayHelper";
import Competitor from "../../models/Competitor";
import Fixture from "../../models/Fixture";
import Result from "../../models/Result";
import FixtureRow from "./FixtureRow";
import Id from "../../models/Id";

class Props {
  userId: Id | undefined;
  competitors!: Competitor[];
  fixtures?: Fixture[] = [];
  saveFixture?: (fixture: Fixture) => Result;
}

const FixtureList = ({ userId, competitors, fixtures, saveFixture }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const startPage = (maxItems: number) => Math.min(Math.max(currentPage - 2, 1), totalPages() - (maxAvailablePages(maxItems) - 1));

  const endPage = (maxItems: number) => Math.max(Math.min(currentPage + 2, totalPages()), maxAvailablePages(maxItems));

  const totalPages = () => distinctBy(fixtures!, "group").length;

  const maxAvailablePages = (maxItems: number) => Math.min(totalPages(), maxItems);

  const availablePages = (maxItems: number): number[] => {
    var pages: number[] = [];
    for (let page = startPage(maxItems); page <= endPage(maxItems); page++) pages.push(page);
    return pages;
  };

  const pageClick = (page: number) => {
    setCurrentPage(page);
  };

  const prevClick = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextClick = () => {
    if (currentPage < totalPages()) setCurrentPage(currentPage + 1);
  };

  const paginationOptions = (maxItems: number) => (
    <>
      <Pagination.Prev onClick={prevClick} />
      {startPage(maxItems) > 1 && <Pagination.Ellipsis disabled />}
      {availablePages(maxItems).map((page) => {
        return (
          <Pagination.Item key={`round${page}`} active={page === currentPage} onClick={() => pageClick(page)}>
            {page}
          </Pagination.Item>
        );
      })}
      {endPage(maxItems) < totalPages() && <Pagination.Ellipsis disabled />}
      <Pagination.Next onClick={nextClick} />
    </>
  );

  return (
    <div className="table-wrapper">
      <div>
        <h4>Round {currentPage}</h4>
        <Accordion alwaysOpen className="pb-4">
          {(fixtures) ?
          fixtures
            .filter((fixture) => fixture.group === currentPage - 1)
            ?.map((fixture, fixtureIndex) => (
              <div key={`fixture${fixtureIndex}`}>
                {fixtureIndex > 0 && <hr />}
                <FixtureRow key={"fixture" + fixtureIndex} userId={userId} competitors={competitors} fixture={fixture} index={fixtureIndex} saveFixture={saveFixture} />
              </div>
            ))
          :
          (
            <Alert variant="warning">No fixtures!</Alert>
          )}
        </Accordion>
        <Row className="justify-content-center">
          <Col className="col-auto">
            <Pagination className="d-none d-lg-flex">{paginationOptions(20)}</Pagination>
            <Pagination className="d-none d-md-flex d-lg-none">{paginationOptions(10)}</Pagination>
            <Pagination className="d-none d-sm-flex d-md-none">{paginationOptions(5)}</Pagination>
            <Pagination className="d-flex d-sm-none pagination-sm">{paginationOptions(5)}</Pagination>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FixtureList;
