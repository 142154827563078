import { Field, FieldProps } from "formik";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

class Props {
  name!: string;
  label?: string;
  type?: string;
  boldLabel?: boolean;
  placeholder?: string;
  width?: string;
  as?: React.ElementType<any>;
}

export const TextField = (props: Props) => {
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => {
        const isValid = !form.errors[field.name];
        return (
          <Form.Group as={props.as} controlId={props.name + "Field"}>
            {props.label && <Form.Label className={props.boldLabel ? "fw-bold" : ""}>{props.label}</Form.Label>}
            <OverlayTrigger placement="top" overlay={form.errors[field.name] ? <Tooltip>{form.errors[field.name] as string}</Tooltip> : <></>}>
              <Form.Control
                {...field}
                autoComplete="off"
                onFocus={handleFocus}
                type={props.type ? props.type : "text"}
                style={props.width ? { width: props.width } : undefined}
                placeholder={props.placeholder}
                isValid={isValid}
                isInvalid={!isValid}
              />
            </OverlayTrigger>
            {/* <Form.Control.Feedback type="invalid">{form.errors[field.name] as string}</Form.Control.Feedback> */}
          </Form.Group>
        );
      }}
    </Field>
  );
};
