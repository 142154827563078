export enum AlertStateType {
  success,
  warning,
  error,
}

export class AlertState {
  type!: AlertStateType;
  message!: string;
}
