import { Button, Row, Col, Form, Accordion } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Competition from "../../models/Competition";
import CompetitorsForm from "../Competitors/CompetitorsForm";
import { TextField } from "../Fields/TextField";
import { SelectField } from "../Fields/SelectField";
import Competitor from "../../models/Competitor";

class Props {
  competition!: Competition;
  competitors!: Competitor[];
  onSubmit!: (competition: Competition) => void;
}

const CompetitionForm = ({ competition, competitors, onSubmit }: Props) => {
  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    type: Yup.string().required("Type is required"),
  });

  return (
    <Formik validationSchema={schema} validateOnMount={true} onSubmit={onSubmit} initialValues={competition ?? new Competition()} enableReinitialize>
      {({ handleSubmit, values, errors, isValid, dirty, isSubmitting, resetForm }) => (
        <Form noValidate onSubmit={handleSubmit} className="mb-4">
          <Accordion alwaysOpen defaultActiveKey={["0", "1"]} className="mb-4">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Competition Details</Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col md={6}>
                    <TextField name="name" label="Competition Name" />
                  </Col>
                  <Col md={6}>
                    <SelectField name="type" label="Type">
                      <option disabled value="">
                        Select an option...
                      </option>
                      <option>League</option>
                      <option>Knockout</option>
                    </SelectField>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Competitors</Accordion.Header>
              <Accordion.Body>
                <CompetitorsForm competitorIds={values?.competitorIds} competitors={competitors} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Debug info</Accordion.Header>
              <Accordion.Body>
                <pre style={{ margin: "0 auto" }}>{JSON.stringify({ ...values, ...errors, isValid, isSubmitting }, null, 2)}</pre>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Row>
            <Col>
              <Button disabled={!dirty || !isValid || isSubmitting} className="me-2" variant="success" as="input" size="lg" type="submit" value="Save" />
              <Button disabled={!dirty || isSubmitting} variant="danger" as="input" size="lg" type="button" value="Reset" onClick={() => resetForm()} />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default CompetitionForm;
