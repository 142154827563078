import { createContext } from "react";

export const Themes = {
  dark: "dark",
  light: "",
};

export const ThemeContext = createContext({
  theme: Themes.dark,
  changeTheme: () => {},
});

// Hack to make this a module
export {}
