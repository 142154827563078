import { Navigate } from "react-router-dom";

class Props {
  logout!: () => void;
}

const Logout = ({ logout }: Props) => {
  logout();
  return <Navigate to="/login" replace={true} />
};

export default Logout;