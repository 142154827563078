import { Container, Nav, Navbar } from "react-bootstrap";
import { TrophyFill } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

class Props {
  loggedIn!: boolean;
}

export default function Header({ loggedIn }: Props) {
  return (
    <header className="App-header">
      <Navbar bg="dark" variant="dark" expand="md">
        <Container>
          <Navbar.Brand>
            <NavLink to={"/"} className="nav-link d-flex align-items-center">
              <TrophyFill className="me-2" />
              <span>Competition Manager</span>
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {loggedIn && (
                <>
                  <NavLink to={"/create-Competition"} className="nav-link">
                    Create Competition
                  </NavLink>
                  <NavLink to={"/list-competitions"} className="nav-link">
                    Competition List
                  </NavLink>
                </>
              )}
            </Nav>
            <Nav>
            <NavLink to={"/unknown"} className="nav-link">
                Error
              </NavLink>
              <NavLink to={"/view-competition/1"} className="nav-link">
                Comp
              </NavLink>
            </Nav>
            <Nav>
              {loggedIn ? (
                <NavLink to={"/logout"} className="nav-link">
                  Logout
                </NavLink>
              ) : (
                <NavLink to={"/login"} className="nav-link">
                  Login
                </NavLink>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
