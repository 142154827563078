import { Alert, Button, Col, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { useState } from "react";
import Competitor from "../../models/Competitor";
import AutoComplete from "../Fields/AutoComplete";
import CompetitorList from "./CompetitorList";
import { Link45deg } from "react-bootstrap-icons";
import { isNullOrWhitespace } from "../../models/StringHelper";

class Props {
  competitors!: Competitor[];
  competitorIds!: (number | string)[];
}

const CompetitorsForm = ({ competitors, competitorIds }: Props) => {
  const [newCompetitor, setNewCompetitor] = useState<string | { id: number; name: string }>("");

  const addNewCompetitor = (e: React.SyntheticEvent, arrayHelpers: FieldArrayRenderProps) => {
    e.preventDefault();
    var competitionId = typeof newCompetitor === "string" ? newCompetitor : newCompetitor.id;
    arrayHelpers.push(competitionId);
    setNewCompetitor("");
  };

  const deleteCompetitor = (competitorId: number | string, arrayHelpers: FieldArrayRenderProps) => {
    if (competitors === undefined) return;
    const index = competitorIds!.findIndex((id) => id === competitorId);
    arrayHelpers.remove(index);
  };

  const handleCompetitorAutoCompleteSelect = (selected: string | { id: number; name: string }) => {
    if (typeof selected === "string") setNewCompetitor(selected);
    else setNewCompetitor(selected);
  };

  const autoCompleteStyle: React.CSSProperties = {
    borderRadius: "inherit",
    margin: "inherit",
  };

  return (
    <FieldArray
      name="competitorIds"
      render={(arrayHelpers) => (
        <>
          {competitorIds && competitorIds.length > 0 ? (
            <CompetitorList
              competitors={competitors}
              competitorIds={competitorIds}
              deleteCompetitor={(competitorId) => deleteCompetitor(competitorId, arrayHelpers)}
            />
          ) : (
            <Alert variant="danger">No competitors added</Alert>
          )}
          <Form.Group as={Col} className="mt-4" controlId="newCompetitorField">
            <Form.Label>New Competitor Name</Form.Label>
            <InputGroup>
              {typeof newCompetitor !== "string" && (
                <>
                  <OverlayTrigger placement="top" overlay={<Tooltip>Linked competitor</Tooltip>}>
                    <InputGroup.Text id="competitor-linked">
                      <Link45deg size={24} />
                    </InputGroup.Text>
                  </OverlayTrigger>
                </>
              )}
              <AutoComplete
                value={newCompetitor!}
                options={(competitors ?? [])
                  .filter((competitor) => competitorIds.indexOf(competitor.id!) === -1)
                  .map((competitor) => ({ id: competitor.id!, name: competitor.name }))}
                onSelect={handleCompetitorAutoCompleteSelect}
                controlStyle={autoCompleteStyle}
              />
              <Button
                variant="warning"
                type="submit"
                disabled={newCompetitor === undefined || (typeof newCompetitor === "string" && isNullOrWhitespace(newCompetitor))}
                onClick={(e) => addNewCompetitor(e, arrayHelpers)}
              >
                Add
              </Button>
            </InputGroup>
          </Form.Group>
        </>
      )}
    />
  );
};

export default CompetitorsForm;
