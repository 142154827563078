interface Obj {
  [key: string]: any;
}

export function distinctBy<T extends Obj, K extends keyof T>(arr: T[], prop: K): T[K][] {
  const uniqueValues: Record<T[K], boolean> = arr.reduce((acc, obj) => {
    if (!acc[obj[prop]]) {
      acc[obj[prop]] = true;
    }
    return acc;
  }, {} as Record<T[K], boolean>);
  return Object.keys(uniqueValues) as T[K][];
}